import React, {
  useState,
  useMemo,
  Fragment,
  useEffect,
  useContext
} from "react";
import { createEditor } from "slate";
import { Slate, Editable, withReact } from "slate-react";
import { withHistory } from "slate-history";
import Stringify, {
  TokenToSlate,
  SlateToExpr
} from "../../modules/paramatters.lib/src/Parser/stringify";
import context from "../Store";

import { withPointRefs, PointRef } from "./pointRef";
import "./styles.css";

const valToSlate = data => {
  console.log("valToSlate", { data });
  if (typeof data === "object") {
    return TokenToSlate(Stringify(data));
    // return fallbackValue(JSON.stringify(data, null, 2));
  } else {
    return fallbackValue(data);
  }
};

const Editor = ({ data, id }) => {
  const [value, setValue] = useState(valToSlate(data));
  const [err, setError] = useState(null);

  const [hasFocus, setFocus] = useState(false);
  const [state] = useContext(context);
  const { points } = state;

  useEffect(() => {
    if (!hasFocus) {
      setValue(valToSlate(data));
    }
  }, [hasFocus, data, id]);

  const editor = useMemo(
    () => withPointRefs(withHistory(withReact(createEditor()))),
    []
  );

  const updateFormula = value => {
    try {
      let exp = SlateToExpr(value);
      let p = points.find(point => {
        console.log("find, ", point);
        return point.id === state.selected;
      });
      p.x(exp);

      setError(null);
    } catch (e) {
      setError(e.message);
    }
    setValue(value);
  };

  const handleBlur = () => {
    setFocus(false);
  };

  const handleFocus = () => {
    setFocus(true);
  };

  return (
    <Fragment>
      <Slate
        editor={editor}
        value={value}
        onChange={value => updateFormula(value)}
      >
        <Editable
          onBlur={handleBlur}
          onFocus={handleFocus}
          renderElement={props => <Element {...props} />}
          placeholder="Enter some text..."
        />
      </Slate>
      {err && `⚠️ ${err}`}
    </Fragment>
  );
};

const Element = props => {
  const { attributes, children, element } = props;

  switch (element.type) {
    case "pointRef":
      return <PointRef {...props} />;
    default:
      return <span {...attributes}>{children}</span>;
  }
};

const fallbackValue = text => [
  {
    type: "text",
    children: [
      {
        text: `${text}` || ""
      }
    ]
  }
];

export default Editor;
