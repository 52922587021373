import {
  Contour,
  Point,
  PointType,
  Operators
} from "../../modules/paramatters.lib";
const { add } = Operators;

const c = new Contour();

// const p1 = new Point(15, 5).type(PointType.line);

const dimension = 50;

const p0 = new Point({
  type: PointType.move,
  x: 10,
  y: 10
});

const p1 = new Point({
  type: PointType.line,
  x: add(p0.$x(), dimension),
  y: p0.$y()
});

const p2 = new Point({
  type: PointType.line,
  x: add(p0.$x(), dimension),
  y: add(p0.$y(), dimension)
});

const p3 = new Point({
  type: PointType.line,
  x: p0.$x(),
  y: add(p0.$y(), dimension)
});

c.points.push(p0);
c.points.push(p1);
c.points.push(p2);
c.points.push(p3);
c.close();

export const initialState = {
  contour: c,
  points: [p0, p1, p2, p3],
  selected: p0.id
};

export const reducer = (state, { type, payload }) => {
  console.log("reducer", { type, payload });
  switch (type) {
    case "SELECT_POINT":
      return {
        ...state,
        selected: payload.id
      };
    default:
      console.log(`no dispatch found for ${type}`, payload);
      return state;
  }
};
