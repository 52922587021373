import React, { Fragment, useState, useEffect, useContext } from "react";
import context from "../Store";

import { Operators } from "../../modules/paramatters.lib";
import SVG from "../../modules/paramatters.lib/src/SVG";
import FormulaEditor from "../FormulaEditor";
import FormulaEditorSmall from "../FormulaEditor/editor";
import "./styles.css";

const { add } = Operators;

const Paramatters = () => {
  const [state, dispatch] = useContext(context);
  const { contour, points } = state;

  console.log("render Paramatters");

  const [data, setData] = useState([contour.resolve()]);

  function handleClick(e) {
    e.preventDefault();

    points
      .find(point => {
        console.log("find, ", point);
        return point.id === state.selected;
      })
      .x(add(10, 30));

    // p0.x(p0.x() + 5).y(p0.y() + 5);
  }

  // With the second parameter
  useEffect(() => {
    contour.register(() => {
      setData([contour.resolve()]);
    });
    setData([contour.resolve()]);
  }, [contour, contour.id]);

  let pX = null;
  if (state.selected) {
    // selPoint = points.find(point => point.id() === state.selected);
    pX = points
      .find(point => {
        console.log("find, ", point);
        return point.id === state.selected;
      })
      .x();
  }

  return (
    <Fragment>
      <h3>view</h3>
      <div>
        <button onClick={handleClick}>change</button>
      </div>
      <svg width="500" height="200">
        <g>
          <SVG data={data} dispatch={dispatch} />
        </g>
      </svg>
      <h3>Test</h3>

      <p>selected: {state.selected}</p>
      {state.selected && (
        <div>
          <p>
            x: <FormulaEditorSmall data={pX} id={state.selected} attr={"x"} />
          </p>
          <p>y: --</p>
        </div>
      )}
      <br />
      <br />
      <pre>{JSON.stringify(data, null, 2)}</pre>

      <br />
      <br />
      <FormulaEditor />
    </Fragment>
  );
};

export default Paramatters;
