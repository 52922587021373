import React, { useReducer } from "react";
import "./App.css";
import Paramatters from "../Paramatters";
import { reducer, initialState } from "../Reducer";

import { Provider } from "../Store";

function App() {
  const useState = useReducer(reducer, initialState);

  return (
    <div className="App">
      <Provider value={useState}>
        <Paramatters />
      </Provider>
    </div>
  );
}

export default App;
