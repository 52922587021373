import React, { useState, useMemo, Fragment } from "react";
import { createEditor } from "slate";
import { Slate, Editable, withReact } from "slate-react";
import { withHistory } from "slate-history";
import Tokenizer from "../../modules/paramatters.lib/src/Parser/tokenizer";
import Parser from "../../modules/paramatters.lib/src/Parser/parser";
import Stringify, {
  TokenToSlate,
  TokenToStr
} from "../../modules/paramatters.lib/src/Parser/stringify";

import { Toolbar } from "./components";
import { withPointRefs, PointRef, InsertPointRefBtn } from "./pointRef";
import "./styles.css";

const Editor = () => {
  const [value, setValue] = useState(initialValue);

  const viewer = useMemo(() => withPointRefs(withReact(createEditor())), []);
  const editor = useMemo(
    () => withPointRefs(withHistory(withReact(createEditor()))),
    []
  );

  const tokens = [];
  let errorTokenizer = null;

  try {
    if (value.length) {
      value.forEach(item => {
        switch (item.type) {
          case "text":
            tokens.push(...Tokenizer(item.children[0].text));
            break;
          case "pointRef":
            tokens.push({
              type: item.type,
              data: { id: item.data.id, attr: item.data.attr }
            });
            break;
          default:
        }
      });
    }
  } catch (e) {
    errorTokenizer = { message: e.message, tokens: e.tokens };
    // throw e
  }

  let parsed = null;
  let errorParser = null;

  try {
    parsed = Parser(tokens);
  } catch (e) {
    errorParser = { message: e.message };
    // throw e
  }

  return (
    <Fragment>
      <Slate editor={editor} value={value} onChange={value => setValue(value)}>
        <Toolbar>
          <InsertPointRefBtn />
        </Toolbar>
        <Editable
          renderElement={props => <Element {...props} />}
          placeholder="Enter some text..."
        />
      </Slate>

      <h3> tree to str</h3>

      <p>{TokenToStr(Stringify(parsed))}</p>
      <h3> tree to slate</h3>
      <Slate
        editor={viewer}
        value={TokenToSlate(Stringify(parsed))}
        onChange={value => value}
      >
        <Editable
          readOnly
          renderElement={props => <Element {...props} />}
          placeholder="no input"
        />
      </Slate>

      <div className="FormulaEditor__debug">
        <pre>{JSON.stringify(value, null, 2)}</pre>
        <pre>
          {!errorTokenizer && JSON.stringify(tokens, null, 2)}
          {errorTokenizer && `⚠️ ${errorTokenizer.message}`}
          {errorTokenizer && JSON.stringify(errorTokenizer.tokens, null, 2)}
        </pre>
        <pre>
          {!errorParser && JSON.stringify(parsed, null, 2)}
          {errorParser && `⚠️ ${errorParser.message}`}
        </pre>

        <pre>
          token: {!errorParser && JSON.stringify(Stringify(parsed), null, 2)}
        </pre>
        <pre>
          slate:
          {!errorParser &&
            JSON.stringify(TokenToSlate(Stringify(parsed)), null, 2)}
        </pre>
      </div>
    </Fragment>
  );
};

const Element = props => {
  const { attributes, children, element } = props;

  switch (element.type) {
    case "pointRef":
      return <PointRef {...props} />;
    default:
      return <span {...attributes}>{children}</span>;
  }
};

const initialValue = [
  {
    type: "text",
    children: [
      {
        text:
          "(3 * 4 + 1) - 1 / (7 / sqrt(3 * 5, 5) + 5) + 5 * 6 * 6 + aber(1) + kk(3) / 3 * (3 * (1)) + "
        // text: "1 + 2 + 3"
      }
    ]
  },
  {
    type: "pointRef",
    data: {
      id: "8a7",
      attr: "x"
    },
    children: [{ text: "" }]
  }
  // {
  //   type: "text",
  //   children: [
  //     {
  //       text: "((4 + 3) * 7)"
  //     }
  //   ]
  // }
];

export default Editor;
